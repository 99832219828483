@tailwind base;
@tailwind components;
@tailwind utilities;

.formatting h1 {
  @apply text-h1 mb-30 mt-50;
  line-height: 1.1;
}

.formatting h2 {
  @apply text-h2 mb-28 mt-42;
  line-height: 1.18;
}

.formatting h3 {
  @apply text-h3 mb-26 mt-38;
  line-height: 1.26;
}

.formatting h4 {
  @apply text-h4 mb-24 mt-34;
  line-height: 1.34;
}

.formatting h5 {
  @apply text-h5 mb-22 mt-32;
  line-height: 1.42;
}

.formatting h6 {
  @apply mb-22 mt-28;
  line-height: 1.5;
}

.formatting h1+h2,
.formatting h1+h3,
.formatting h1+h4,
.formatting h1+h5,
.formatting h1+h6,
.formatting h2+h2,
.formatting h2+h3,
.formatting h2+h4,
.formatting h2+h5,
.formatting h2+h6,
.formatting h3+h3,
.formatting h3+h4,
.formatting h3+h5,
.formatting h3+h6,
.formatting h4+h4,
.formatting h4+h5,
.formatting h5+h6,
.formatting h6+h6,
.formatting h1:first-child,
.formatting h2:first-child,
.formatting h3:first-child,
.formatting h4:first-child,
.formatting h5:first-child,
.formatting h6:first-child {
  @apply mt-0;
}

.formatting h1,
.formatting h2,
.formatting h3,
.formatting h4,
.formatting h5,
.formatting h6 {
  @apply font-heading text-gray-700;
}

.formatting ul {
  @apply list-disc;
}

.formatting ol {
  @apply list-decimal;
}

.formatting ul,
.formatting ol {
  @apply pl-20;
}

.formatting blockquote {
  @apply p-20 border-l-4 text-h5 border-gray-300;
}

.formatting blockquote > :last-child {
  @apply mb-0;
}

.formatting p,
.formatting ul,
.formatting ol,
.formatting hr,
.formatting pre,
.formatting figure,
.formatting blockquote,
.formatting table {
  @apply mb-18;
}

.formatting li {
  @apply mb-7;
}

.formatting img,
.formatting svg {
  @apply inline-block align-baseline;
}

.formatting a {
  @apply text-blue underline;
}

.formatting table {
  @apply w-full;
}

.formatting table thead th {
  @apply align-bottom border-b-2 border-gray-90;
}

.formatting table td,
.formatting table th {
  @apply p-12 border-t border-gray-90;
}

.formatting th {
  text-align: inherit;
}

@layer components {
  .container {
    @apply max-w-1200 w-full-x-20 mx-auto;
  }

  .btn {
    @apply inline-block whitespace-nowrap text-center text-gray-700 text-20 font-bold border border-primary-lighter pt-10 px-22 pb-12 transition duration-300 ease-in-out hover:bg-gray-50 focus:bg-gray-50;
  }

  .btn--primary {
    @apply bg-primary border-primary text-white hover:bg-primary-lighter focus:bg-primary-darker;
  }

  .btn--lg {
    @apply pt-14 px-24 pb-16;
  }

  .btn--small {
    @apply py-4 px-14 text-16;
  }
}