.contact-content {
  .wpcf7-form {
    overflow: hidden;

    input,
    select,
    textarea {
      padding: rem(10);
      border: rem(1) solid #C2C2C2;
      color: #555770;
      font-family: "Montserrat", sans-serif;
      width: 100%;
      margin-bottom: rem(25);

      &::placeholder {
        color: #555770;
        opacity: 1;
      }

      &[type='submit'] {
        margin-left: rem(15);
        margin-right: rem(15);
        background: #60A039;
        border: 2px solid #60A039;
        box-shadow: inset rem(-2.5) 0 0 #fff, inset 0 rem(-2.5) 0 #fff, inset rem(2.5) 0 0 #fff, inset 0 rem(2.5) 0 #fff;
        color: #fff;
        font-family: "Cormorant", serif;
        font-weight: bold;
        font-size: rem(18);
        line-height: rem(22);
        text-align: center;
        text-transform: uppercase;
        padding: rem(15) rem(50);
        width: auto;
        max-width: 100%;
        cursor: pointer;

        @media (max-width: rem(991)) {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }

    select {
      position: relative;
      appearance: none;
      background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.3 1.27L6.37 6.54.44 1.27' stroke='%23D27730'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position-x: 98%;
      background-position-y: 50%;
      padding-right: 5%;
    }

    textarea {
      resize: none;
      max-height: rem(150);
    }

    &-row {
      p {
        display: flex;
        margin: 0 rem(-15);
        align-items: flex-start;

        @media (max-width: rem(991)) {
          flex-direction: column;
          margin: 0;
        }
      }

      .wpcf7-form-control-wrap {
        margin-left: rem(15);
        margin-right: rem(15);
        width: 100%;

        @media (max-width: rem(991)) {
          margin-left: 0;
          margin-right: 0;
        }

        .wpcf7-not-valid-tip {
          position: relative;
          bottom: rem(20);
          font-family: "Montserrat", sans-serif;
        }
      }
    }

    .wpcf7-response-output {
      color: #555770;
      font-family: "Montserrat", sans-serif;
      padding: rem(5) rem(10);
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.buy-tickets-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: rem(400);
  margin: 0 auto;

  select {
    padding: rem(10);
    border: rem(1) solid #C2C2C2;
    color: #555770;
    font-family: "Montserrat", sans-serif;
    width: 100%;

    &::placeholder {
      color: #555770;
      opacity: 1;
    }

    &[type='submit'] {
      margin-left: rem(15);
      margin-right: rem(15);
      background: #60A039;
      border: 2px solid #60A039;
      box-shadow: inset rem(-2.5) 0 0 #fff, inset 0 rem(-2.5) 0 #fff, inset rem(2.5) 0 0 #fff, inset 0 rem(2.5) 0 #fff;
      color: #fff;
      font-family: "Cormorant", serif;
      font-weight: bold;
      font-size: rem(18);
      line-height: rem(22);
      text-align: center;
      text-transform: uppercase;
      padding: rem(15) rem(50);
      width: auto;
      max-width: 100%;
      cursor: pointer;

      @media (max-width: rem(991)) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}