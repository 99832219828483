.swiper {
  &-wrapper {
    max-height: 100%;
    height: 100%;
  }

  &-button-prev:after,
  &-button-next:after {
    @apply text-darkgreen;
  }

  &-pagination {
    &-bullet {
      @apply bg-darkgreen;
    }

    &-bullets {
      position: absolute;
      bottom: rem(-30) !important;
    }
  }
}