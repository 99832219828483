.text-align-inherit {
  text-align: inherit;
}

.text-format {
  p {
    margin-bottom: rem(22);
  }

  p, li {
    line-height: 1.67;
    font-size: rem(18);

    @media (min-width: rem(768)) {
      font-size: rem(20);
    }
  }

  h2 {
    font-family: 'PT Serif', ;
    font-weight: bold;
    font-size: rem(35);
    line-height: rem(44);
    color: #1C1C28;
    margin-bottom: rem(32);
  }

  h3 {
    font-family: 'PT Serif';
    font-size: rem(32);
    line-height: rem(35);
    color: #333;
    margin-bottom: rem(23);
  }

  h4 {
    font-family: 'PT Serif';
    font-size: rem(28);
    line-height: rem(31);
    color: #333;
    margin-bottom: rem(23);
  }

  a {
    color: #60A039;
  }
}

.list-two-columns {
  @media (min-width: rem(768)) {
    column-count: 2;
  }
}