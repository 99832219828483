.main-nav {
  max-height: calc(100vh - #{rem(48)});
}

[aria-expanded="true"] {
  + .main-nav {
    top: 0;
  }

  + .submenu:not(.submenu--deep) {
    top: 100%;
  }

  + .main-nav,
  + .submenu {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
  }
}

.main-nav,
.submenu {
  transition: height ease .3s, opacity ease .3s, visibility .3s, top 0s .3s;
}

@media (hover: hover) {
  .submenu {
    pointer-events: none;
  }

  .has-submenu {
    &:hover {
      > .submenu {
        visibility: visible;
        opacity: 1;
        transition-delay: 0s;
        pointer-events: all;

        &:not(.submenu--deep) {
          top: 100%;
        }
      }
    }
  }
}

header {
  div.topbar-language-container {
    .goog-te-combo {
      margin: 0 !important;
    }

    div.skiptranslate.goog-te-gadget {
      display: flex !important;

      div {
        select.goog-te-combo {
          appearance: none;
          font-size: rem(16);
          font-weight: 700;
        }
      }
    }

    #google_language_translator .goog-te-gadget .goog-te-combo {
      padding: rem(4) rem(14) !important;
      width: 100% !important;
      min-width: rem(155);
    }
  }
}