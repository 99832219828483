blockquote {
  p {
    &:last-of-type {
      display: inline;
    }
  }
}

.text-format {
  blockquote {
    margin: rem(44) 0;
    position: relative;
    overflow: hidden;
    background-color: #60A039;
    padding: rem(45) rem(30);
    color: #fff;
    font-family: "Playfair Display", serif;
    font-size: rem(25);
    line-height: rem(32);
    
    @media (min-width: rem(769)) {
      padding: rem(55) rem(35) rem(65) rem(40);
    }
    
    &:before {
      content: "";
      background-color: #fff;
      border-color: transparent transparent #4B7B2D transparent;
      border-style: solid;
      border-width: 0 0 rem(30) rem(30);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
    
    &:after {
      content: "";
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: .05;
    }
  }
}