.list-inner {
  li {
    &:before {
      content: "\2022";
      color: #60A039;
      font-weight: 700;
      display: inline-block;
      width: rem(15);
      margin-left: rem(-15);
    }
  }
}