.wysiwyg-content {
  img, a img {
    &.aligncenter {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    &.alignright,
    &.alignleft {
      margin-left: auto;
      margin-right: auto;
    }

    @media (min-width: rem(992)) {
      &.alignright {
        float: right;
        margin: 0 0 rem(30) rem(15);
      }

      &.alignleft {
        float: left;
        margin: 0 rem(30) rem(15) 0;
      }
    }
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  ol, ul {
    margin-bottom: rem(22);
    padding-left: rem(25);
  }

  table {
    border-collapse: collapse;
    width: 100%;

    td, th {
      border: 1px solid #d2d2d2;
      padding: 8px;
    }

    tr:nth-child(even){
      background-color: #f7f7f7;
    }

  }

  a {
    @apply text-blue underline;
  }
}